import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import { useAppSelector } from "../../../reduxStore/hooks";
import CloseIcon from "@mui/icons-material/Close";

const StyledSnackbar = () => {
  const { open, message, severity, anchorOrigin, autoHideDuration } =
    useAppSelector((state) => state.snackbar);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
      action={action}
      sx={{ zIndex: 2000, mt: "2rem" }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default StyledSnackbar;
