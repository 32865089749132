/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import {
  addCourseIdInToRefreshingCourses,
  setSelectedCourse,
  setShowGrader,
} from "../../../../../../reduxStore/features/adminCourseSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BorderLinearProgress } from "../../../../../components/styledComponents/ProgressBar";
import { loadSingleCourseDataAction } from "../../../../../../reduxStore/middleware/adminCourseMiddleware";

type Props = {
  course: any;
  selectedView?: boolean;
};

const AdminCourseCard = (props: Props) => {
  const { selectedCourse } = useAppSelector((state: any) => state.adminCourse);
  const [overAllCourseProgress, setOverallCourseProgress] = useState("0.0");
  const dispatch = useAppDispatch();
  const handleCourseSelection = () => {
    dispatch(setSelectedCourse({ courseId: props.course._id }));
  };
  const handleShowGrader = async () => {
    // navigate(`/admin/courses/${props?.course?.courseName}`)
    dispatch(
      addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id })
    );
    await dispatch(loadSingleCourseDataAction({ courseId: props.course._id }));
    dispatch(setShowGrader(true));
  };
  useEffect(() => {
    if (props.selectedView) {
      const totalStudents =
        // eslint-disable-next-line array-callback-return
        selectedCourse?.userList?.filter((user: any) => {
          if (user?.role !== null && user?.role === "Student") {
            return user;
          }
        }) || [];
      const accumulatedProgress = totalStudents.reduce(
        (accumulator: any, student: any) => {
          return accumulator + student.progress;
        },
        0
      );
      const expectedProgress =
        totalStudents.length * selectedCourse.courseProgressPoints;
      const progressPercentage = parseFloat(
        ((accumulatedProgress / expectedProgress) * 100).toString()
      ).toFixed(2);
      setOverallCourseProgress(progressPercentage);
    } else {
      const totalStudents =
        props?.course?.userList?.filter(
          (user: any) => user?.role !== null && user?.role === "Student"
        ) || [];
      const accumulatedProgress = totalStudents.reduce(
        (accumulator: any, student: any) => {
          return accumulator + student.progress;
        },
        0
      );
      const expectedProgress =
        totalStudents.length * props?.course?.courseProgressPoints;
      const progressPercentage = parseFloat(
        ((accumulatedProgress / expectedProgress) * 100).toString()
      ).toFixed(2);
      setOverallCourseProgress(progressPercentage);
    }
  }, [selectedCourse]);
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        boxShadow:
          selectedCourse._id === props.course._id && !props.selectedView
            ? "0px 4px 4px 0px #00000040"
            : "var(--admin-course-card-shadow)",
        borderRadius: "5px",
        border:
          selectedCourse._id === props.course._id && !props.selectedView
            ? "1px solid var(--accent-clr2)"
            : "",
        width: "100%",
        minHeight: "217px",
        maxHeight: "217px",
        padding: { lg: "10px 25px", md: "10px", sm: "5px", xs: "5px" },
        // cursor: "pointer",
        marginRight: "5px",
      }}
      onClick={() => {
        handleCourseSelection();
      }}
      cy-data="admin-course-card"
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          minHeight: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Typography
            sx={{
              fontSize: props.selectedView ? "33px" : "17px",
              fontWeight: 900,
              lineHeight: props.selectedView ? "45px" : "23px",
              letterSpacing: "0.05em",
              textAlign: "left",
              color: "var(--accent-clr3)",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            cy-data="admin-course-card-name"
          >
            {!props.selectedView
              ? props.course.courseName
              : selectedCourse.courseName}
          </Typography>
        </Grid>
        {props.selectedView && (
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-end",
                lg: "flex-end",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "17x",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "var(--text-primary-clr1)",
                paddingRight: "10px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {`Course deployed in: `}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                lineHeight: "17x",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "var(--text-primary-clr1)",
                width: "50%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {`${
                selectedCourse?.orgData?.orgName
                  ? `${selectedCourse?.orgData?.orgName} (${selectedCourse?.visibility})`
                  : `${selectedCourse?.visibility}`
              }`}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 900,
            lineHeight: "17x",
            letterSpacing: "0.05em",
            textAlign: "left",
            color: "#AFAFAF",
          }}
          cy-data="admin-course-card-description"
        >
          {!props.selectedView
            ? props.course.courseDescription
            : selectedCourse.courseDescription}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={props.selectedView ? 8 : 12}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "21x",
              letterSpacing: "0.05em",
              textAlign: "left",
              color: "#AFAFAF",
            }}
            cy-data="admin-course-card-progress-label"
          >
            Overall Course Progress
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "21x",
              letterSpacing: "0.05em",
              textAlign: "left",
              color: "#AFAFAF",
            }}
          >
            {overAllCourseProgress}
            /100
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BorderLinearProgress
            variant="determinate"
            value={parseFloat(overAllCourseProgress)}
            cy-data="admin-course-card-progress-bar"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={props.selectedView ? 4 : 12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        {props.selectedView && (
          <Button
            sx={{
              height: 40,
              minWidth: "67px",
              textTransform: "capitalize",
              fontSize: 14,
              fontWeight: 400,
              border: "none",
              color: "black",
              backgroundImage:
                "linear-gradient(110deg,#ff8d8d78 50%,#FF8D8DC9 50%)",
              backgroundSize: "222%",
              backgroundPosition: "right",
              transition:
                "background-position 200ms linear, letter-spacing 200ms linear",
              ":hover": {
                backgroundPosition: "left",
              },
            }}
          >
            Admin
          </Button>
        )}
        <Button
          sx={{
            height: 40,
            minWidth: "167px",
            textTransform: "capitalize",
            fontSize: 14,
            fontWeight: 400,
            border: "none",
            color: "white",
            backgroundImage:
              "linear-gradient(110deg,var(--accent-clr1) 50%,var(--accent-clr2) 50%)",
            backgroundSize: "222%",
            backgroundPosition: "right",
            transition:
              "background-position 200ms linear, letter-spacing 200ms linear",
            ":hover": {
              backgroundPosition: "left",
            },
          }}
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => handleShowGrader()}
          cy-data="admin-course-card-open-grader-btn"
        >
          Open Grader
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdminCourseCard;
