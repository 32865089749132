/**
 * @fileOverview This file handles all rgva event and actions
 * @module rgva
 */

import Cookies from "universal-cookie";
const cookies = new Cookies();
type Data = {
  event: any; // Adhere to snake_case convention
  category: string;
  action: any;
  label: string;
  nonInteraction: boolean;
  userId?: any;
};
export const sendRGAEvent = (label: string, action: any) => {
  const gtagId = cookies.get("GTAGID");
  const actionSnakeCase = action.toLowerCase().replace(" ", "_");
  const data: Data = {
    event: actionSnakeCase, // Adhere to snake_case convention
    category: "account",
    action: action,
    label: label,
    nonInteraction: true,
  };
  if (gtagId) data.userId = gtagId;
  window.dataLayer.push(data);
};
