import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { BookmarkAddOutlined, BookmarkAdded } from "@mui/icons-material";
import moment from "moment";
import { BlogType } from "../../../reduxStore/types/BlogTypes";
import { DOMAIN_CONFIG_OBJ } from "../../../utils/axiosInstance";

interface IBlogCardProp {
  blog: BlogType;
  handleAddBookmark: (id: string) => void;
  isBookmarked: boolean;
  minimumHeight?: string;
  isLandingPage?: boolean;
}

const BlogCard = ({
  blog,
  handleAddBookmark,
  isBookmarked,
  minimumHeight,
  isLandingPage,
}: IBlogCardProp) => {
  const { cardInfo } = blog;

  const handleCardClick = (id: string) => {
    window.location.href = `/blogs/?file=${id}`;
  };

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        display: "flex",
        padding: 2,
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: isLandingPage ? "var(--landing-course-card-bg)" : "",
        "&:hover": { backgroundColor: theme.palette.background.base },
        minHeight: {
          xs: "",
          sm: "",
          md: "",
          lg: minimumHeight ? minimumHeight : "",
        },
      })}
      onClick={() => isLandingPage && handleCardClick(blog?._id)}
    >
      <CardContent sx={{ p: { xs: 0, sm: 2 }, flex: 1 }}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
          <Typography
            fontSize={14}
            color="text.primary"
            aria-describedby="author"
            sx={{
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "1",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordBreak: "break-word",
            }}
          >
            {cardInfo?.userName}
          </Typography>
          <Typography
            fontSize={14}
            color="text.secondary"
            sx={{
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "1",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordBreak: "break-word",
            }}
          >
            • {moment(cardInfo?.datePublished).format("MMMM Do YYYY")}
          </Typography>
        </Box>
        <Box onClick={() => handleCardClick(blog?._id)}>
          <Typography
            variant="h6"
            fontSize={{ xs: 16, sm: 20 }}
            fontWeight={600}
            color="text.primary"
            sx={{
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordBreak: "break-word",
            }}
          >
            {cardInfo?.title}
          </Typography>
          <Typography
            variant="body1"
            fontSize={16}
            mb={isLandingPage ? 0 : 4}
            display={{ xs: "none", sm: "block" }}
            sx={
              isLandingPage
                ? {
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "1",
                    display: "-webkit-box",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordBreak: "break-word",
                  }
                : {}
            }
          >
            {cardInfo?.description}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" flexWrap="wrap">
          {cardInfo?.tags?.map((tag: string) => (
            <Chip
              key={tag}
              label={tag}
              variant="filled"
              size="small"
              sx={{ fontSize: 13 }}
            />
          ))}
          <Typography fontSize={14} ml={1} color="text.secondary">
            • {cardInfo?.numberViews} views
          </Typography>
          <Box
            display={{ xs: "none", sm: "flex" }}
            alignItems="center"
            ml="auto"
            gap={1.5}
          >
            <IconButton
              size="small"
              onClick={() => handleAddBookmark(blog._id)}
            >
              {!isBookmarked ? (
                <BookmarkAddOutlined fontSize="inherit" color="action" />
              ) : (
                <BookmarkAdded
                  fontSize="inherit"
                  sx={{ color: "var(--accent-clr1)" }}
                />
              )}
            </IconButton>
          </Box>
        </Box>
      </CardContent>
      <Stack>
        <CardMedia
          image={cardInfo?.imageLink || DOMAIN_CONFIG_OBJ.simpleLogo}
          sx={{
            width: { xs: 75, sm: 125 },
            maxHeight: { xs: 75, sm: 125 },
            aspectRatio: 1,
            objectFit: "cover",
            flexShrink: 0,
            backgroundColor: "black",
            borderRadius: "12px",
            boxShadow: "0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9",
          }}
          onClick={() => handleCardClick(blog?._id)}
        />
        <Box
          display={{ xs: "flex", sm: "none" }}
          alignItems="center"
          justifyContent="flex-start"
          ml="auto"
          gap={1.5}
        >
          <IconButton size="small">
            <BookmarkAddOutlined fontSize="inherit" color="action" />
          </IconButton>
        </Box>
      </Stack>
    </Card>
  );
};

export default BlogCard;
