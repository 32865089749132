import * as React from "react";

import TextField from "@mui/material/TextField";
import { InputAdornment, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const StyledAdminTextField = styled(TextField)({
  padding: "0px !important",
  "& .MuiInputBase-input": {
    padding: "10px",
  },
  "& .MuiInputBase-root": {
    padding: "0",
  },
  color: "black",
  "& label.Mui-focused": {
    color: "var(--text-primary-clr1)",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    padding: "7px 14px !important ",
    "& fieldset": {
      borderColor: "var(--text-primary-clr1)",
    },
    "&:hover fieldset": {
      borderColor: "var(--text-primary-clr1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--text-primary-clr1)",
    },
  },
});

export const StyledTextField = styled(TextField)({
  color: "var(--text-primary-clr1) !important",
  "& label.Mui-focused": {
    color: "var(--accent-clr1)",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 !important",
    color: "var(--text-primary-clr1) !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    color: "var(--text-primary-clr1) !important",
    padding: "7px 12px !important ",
    "& fieldset": {
      borderColor: "var(--accent-clr1)",
    },
    "&:hover fieldset": {
      borderColor: "var(--accent-clr1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--accent-clr1)",
    },
  },
});
type Props = {
  value?: any;
  handler?: any;
  placeHolder?: any;
};
export default function CustomSearchField({
  value,
  handler,
  placeHolder,
}: Props) {
  return (
    <StyledTextField
      placeholder={placeHolder ? placeHolder : "Search"}
      value={value}
      onChange={(e) => handler(e.target.value)}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "var(--accent-clr2)" }} />
          </InputAdornment>
        ),
      }}
      sx={{
        background: "var(--bg-primary-clr1)",
      }}
    />
  );
}
