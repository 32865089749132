import React from "react";
import {
    Accordion,
    Button,
    Divider,
    Grid,
    Typography,
    AccordionSummary as MuiAccordionSummary,
    styled,
    colors,
    Box,
    AccordionDetails,
    Chip,
    IconButton,
    CircularProgress,
} from "@mui/material";
import { Close, ExpandCircleDown } from "@mui/icons-material";
import { StyledSwitch } from "../../styledComponents/StyledSwitch";
import { IEnlargedFormProps } from "../types";
import { ConsentTypes } from "../../../../types/domainType";

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandCircleDown fontSize="small" color="disabled" />}
        {...props}
    />
))(({ theme }) => ({
    border: "1px solid transparent",
    backgroundColor:
        theme.palette.mode === "dark" ? colors.grey[800] : colors.blueGrey[50],
    flexDirection: "row-reverse",
    "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: theme.palette.divider,
        borderBottom: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(-90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        marginLeft: theme.spacing(1),
    },
}));

const EnlargedForm = ({
    cookieTypes,
    preferedCookies,
    loading,
    handleManagePreferences,
    choosePreference,
    savePreference,
    acceptAll,
    rejectAll,
}: IEnlargedFormProps) => {
    const handleCookiePreferenceClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        type: keyof ConsentTypes
    ) => {
        event.stopPropagation();
        choosePreference(type);
    };

    return (
        <>
            <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography
                        variant="h6"
                        fontWeight={600}
                        color="text.primary"
                        fontSize={16}
                    >
                        Cookies consent preferences
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleManagePreferences}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid
                item
                container
                spacing={2}
                xs={12}
                maxHeight={+230 + +cookieTypes.length * 128}
                sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarGutter: "stable",
                }}
            >
                <Grid item container xs={12} spacing={1.5}>
                    <Grid item xs={12}>
                        <Typography
                            fontSize={14.4}
                            fontWeight={600}
                            color="text.primary"
                        >
                            Tracking technologies and your consent
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            fontSize={14}
                        >
                            Cookies are small files that websites place on
                            users&apos; devices to collect data and enhance user
                            experience. They can remember preferences, and track
                            browsing habits. You can modify your preferences
                            regarding cookies and tracking at any time.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Accordion elevation={0}>
                            <AccordionSummary aria-controls="panel1d-content">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flex={1}
                                >
                                    <Typography fontSize={14} fontWeight={600}>
                                        Strictly Necessary Cookies
                                    </Typography>
                                    <Box
                                        display="flex"
                                        gap={0.5}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Chip
                                            size="small"
                                            color="default"
                                            label="Always enabled"
                                            sx={{ fontSize: 12, mt: "5px" }}
                                        />
                                        <StyledSwitch
                                            size="small"
                                            checked
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={(theme) => ({
                                    border: "1px solid",
                                    borderColor: theme.palette.divider,
                                    borderRadius: "0px 0px 8px 8px",
                                })}
                            >
                                <Typography
                                    fontSize={14}
                                    color="text.secondary"
                                >
                                    Strictly necessary cookies are essential for
                                    website functionality, enabling basic
                                    features like navigation and access to
                                    secure areas. Without these cookies,
                                    services you have requested cannot be
                                    provided.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {cookieTypes.map((cookie) => (
                        <Grid item xs={12} key={cookie.id}>
                            <Accordion elevation={0}>
                                <AccordionSummary aria-controls="panel1d-content">
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        flex={1}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={600}
                                        >
                                            {cookie.name}
                                        </Typography>
                                        <StyledSwitch
                                            size="small"
                                            checked={
                                                preferedCookies[cookie.id] ===
                                                    "granted" && true
                                            }
                                            onClick={(e) =>
                                                handleCookiePreferenceClick(
                                                    e,
                                                    cookie.id
                                                )
                                            }
                                            disabled={loading}
                                        />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={(theme) => ({
                                        border: "1px solid",
                                        borderColor: theme.palette.divider,
                                        borderRadius: "0px 0px 8px 8px",
                                    })}
                                >
                                    <Typography
                                        fontSize={14}
                                        color="text.secondary"
                                    >
                                        {cookie.description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
                <Grid item>
                    <Box
                        padding={2}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        sx={(theme) => ({
                            border: "1px solid",
                            borderColor: theme.palette.divider,
                            borderRadius: 2,
                        })}
                    >
                        <Typography fontSize={14.4} fontWeight={600}>
                            More information
                        </Typography>
                        <Typography fontSize={14}>
                            For any query in relation to the policy on cookies
                            and your choices, please{" "}
                            <a
                                href="https://www.qbraid.com/contact"
                                target="_blank"
                                rel="noreferrer"
                            >
                                contact us
                            </a>
                            .
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {loading ? (
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    p={0.75}
                >
                    <Grid item>
                        <CircularProgress size={24} />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            fontSize={15}
                        >
                            Confirming preferences...
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid item container spacing={1} justifyContent="space-between">
                    <Grid item container spacing={1} xs={12} sm={6}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                size="small"
                                fullWidth
                                sx={{ borderRadius: "100vw", fontSize: 14 }}
                                onClick={acceptAll}
                            >
                                Accept all
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ borderRadius: "100vw", fontSize: 14 }}
                                onClick={rejectAll}
                            >
                                Reject all
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ borderRadius: "100vw", fontSize: 14 }}
                            onClick={savePreference}
                        >
                            Save preference
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default EnlargedForm;
