import React, { useState } from "react";
import googleImg from "../../../../../assets/img/google.png";
import { useAppDispatch } from "../../../../../reduxStore/hooks";
import {
  authSignIn,
  federatedSignIn,
} from "../../../../../reduxStore/middleware/authMiddleware";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import "./index.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ModalTypes } from "../..";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { DOMAIN_CONFIG_OBJ } from "../../../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../../../utils/constants/domainNameValues";

interface ISignInProps {
  toggleAuthFlowPage: (modalToBeOpened: ModalTypes) => void;
  open: boolean;
}

const SignIn = ({ toggleAuthFlowPage, open }: ISignInProps) => {
  const [authenticating, setAuthenticating] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const responseGoogle = () => {
    setAuthenticating(true);
    console.log("signing in with Google redirection to ");
    dispatch(federatedSignIn(CognitoHostedUIIdentityProvider.Google));
  };

  const showPasswordHandler = (): void => {
    setShowPassword((prev) => !prev);
  };

  const verifyEmail = (email: string | undefined): boolean => {
    if (!email) return false;
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(email);
  };

  const verifyPassword = (password: string | undefined): boolean => {
    if (!password) return false;
    return password.length < 8 ? false : true;
  };

  const handleLogin = (
    event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>,
  ) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const email = formData.get("email")?.toString();
    const password = formData.get("password")?.toString();

    const isEmailCorrect = verifyEmail(email);
    const isPasswordCorrect = verifyPassword(password);

    if (!isEmailCorrect || !isPasswordCorrect) {
      setErrorMessage("Please enter a valid email and password");
      return;
    }

    if (email && password) {
      setErrorMessage("");
      let queryParams = new URLSearchParams(window.location.search);
      let redirectUriFromStorage = window.localStorage.getItem("redirectUri");
      // redirectTo is the url that the user was trying to access before they logged in
      let redirectUri =
        queryParams.get("redirectTo") !== null
          ? queryParams.get("redirectTo")
          : redirectUriFromStorage !== null
            ? "/" + redirectUriFromStorage
            : "/";

      setAuthenticating(true);
      dispatch(authSignIn({ email, password }))
        .then((data) => {
          setErrorMessage("");
          try {
            if (data.type.includes("fulfilled")) {
              if (redirectUri) {
                window.location.href = redirectUri;
              }
            }
          } catch (error) {
            console.log("navigating", error);
          }
        })
        .catch((err) => {
          setErrorMessage("Incorrect email or password");
          setAuthenticating(false);
        });
    }
  };

  return (
    <Dialog
      open={open}
      hideBackdrop
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          backgroundColor: "var(--auth-form-bg)",
          width: { xs: "100%", sm: "100%", md: "100%", lg: 430 },
          p: { xs: "1em", sm: "1em", md: "1em", lg: "1em 3em" },
          m: { xs: 0, sm: 0, md: 0, lg: 32 },
          borderRadius: "8px",
          boxShadow: "0px 1px 15px rgba(199, 199, 199, 0.5)",
          color: theme.palette.common.white,
        },
      }}
      aria-label="login form"
      cy-data="login-form"
    >
      <DialogContent sx={{ padding: 1 }}>
        <Stack
          px={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className={`login-logo ${
              DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam
                ? "login-logo-qusteam"
                : DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qbraid
                  ? "login-logo-qbraid"
                  : ""
            }`}
            src={DOMAIN_CONFIG_OBJ.darkThemeLogo}
            alt="qbook logo"
            cy-data="login-logo"
          />
        </Stack>
        <form
          autoComplete="off"
          spellCheck="false"
          // className="login-input-holder"
          onSubmit={handleLogin}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                id="googleSignInButton"
                fullWidth
                onClick={responseGoogle}
                size="large"
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                cy-data="login-google-button"
              >
                <img src={googleImg} alt="logo" width="28px" height="28px" />
                Sign in with Google
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle textAlign="center" fontSize={28} color="#f5f5f5">
                Sign in to {DOMAIN_CONFIG_OBJ.domainName}
              </DialogTitle>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="email"
                variant="outlined"
                type="text"
                placeholder="Email"
                cy-data="email"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                cy-data="password"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={showPasswordHandler}
                          sx={{
                            color: "#2E3338",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <Visibility
                          onClick={showPasswordHandler}
                          sx={{
                            color: "#2E3338",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => toggleAuthFlowPage("forget_password")}
                variant="text"
                sx={{
                  color: "var(--accent-clr6)",
                  textTransform: "none",
                  fontSize: 16,
                  textAlign: "left",
                  maxWidth: "fit-content",
                }}
              >
                Forgot Password?
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                color="authSubmit"
                size="large"
                type="submit"
                cy-data="login-submit"
                fullWidth
              >
                {authenticating ? "Logging in..." : "Log in"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </Grid>
          </Grid>
        </form>
        <p className="sign-up-link">
          <span>New to {DOMAIN_CONFIG_OBJ.domainName}? </span>
          <button
            className="sign-up-link-text"
            onClick={() => toggleAuthFlowPage("signup")}
          >
            Create an account
          </button>
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default SignIn;
