import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import { Box, Typography } from "@mui/material";
import CourseCard from "./courseCard";
import CourseTable from "./courseTable";
import { CourseCardLoader, CourseUserTableLoader } from "./CourseLoaders";
import { loadSingleCourseDataAction } from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import { addCourseIdInToRefreshingCourses } from "../../../../../../reduxStore/features/adminCourseSlice";

type Props = {};

const CoursePreviewCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const { selectedCourse, loadingAdminCourses } = useAppSelector(
    (state) => state.adminCourse,
  );
  const handleRefresh = async () => {
    dispatch(
      addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id }),
    );
    await dispatch(
      loadSingleCourseDataAction({ courseId: selectedCourse._id }),
    );
  };
  return (
    <>
      {loadingAdminCourses ? (
        <>
          <CourseCardLoader selectedView={true} />
          <CourseUserTableLoader />
        </>
      ) : selectedCourse ? (
        <>
          <CourseCard course={selectedCourse} selectedView={true} />
          {selectedCourse?.userList && (
            <CourseTable
              handleRefresh={handleRefresh}
              itemId={selectedCourse._id}
            />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "217px",
            maxHeight: "217px",
            boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: "1em",
            padding: "1.5em",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              color: "#858585",
              fontWeight: 700,
            }}
          >
            No preview available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CoursePreviewCard;
