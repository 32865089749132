import React from "react";
import "./index.css";
import AdminHeader from "./components/header";
import { Outlet, useLoaderData } from "react-router-dom";
import UnauthorizedUI from "./components/unauthorizedUi/unauthorizedUi";
import { COURSE_ADMIN_PERMISSION } from "../../../utils/constants/permissionNodes";
type Props = {};

const AdminLayout = (props: Props) => {
  const userData: any = useLoaderData();
  return (
    <div className="admin-parent-container">
      {userData?.data?.permissionsNodes?.includes(COURSE_ADMIN_PERMISSION) ? (
        <>
          <AdminHeader />
          {/* <NavigationBar /> */}
          <Outlet />
        </>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
  );
};

export default AdminLayout;
