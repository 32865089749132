import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import "./courseTable.css";
import { BorderLinearProgress } from "../../../../../components/styledComponents/ProgressBar";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import CustomSearchField from "../../../../../components/styledComponents/customTextField";
import {
  CourseUser,
  addCourseIdInToRefreshingCourses,
  setSelectedUser,
  setShowGrader,
} from "../../../../../../reduxStore/features/adminCourseSlice";

type PropsTypes = {
  handleRefresh?: any;
  itemId?: any;
  graderTable?: boolean;
};
const UserTableList = ({ handleRefresh, itemId, graderTable }: PropsTypes) => {
  const dispatch = useAppDispatch();
  const { showGrader } = useAppSelector((state) => state.adminCourse);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [userData, setUserData] = useState<Array<any>>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = useState<string>("reset");
  const [rotateRefresh, setRotateRefresh] = useState<boolean>(false);
  const { refreshingCourses, selectedCourse, selectedCourseUserList } =
    useAppSelector((state) => state.adminCourse);
  useEffect(() => {
    let filteredItems = selectedCourseUserList || [];
    filteredItems = sortFiles(filteredItems, selectedSort);
    filteredItems = searchUsers(filteredItems, searchQuery);
    setUserData(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, searchQuery, selectedCourseUserList]);

  function sortFiles(items: Array<CourseUser>, sortBy: string) {
    if (selectedSort === "reset") {
      return items;
    }
    switch (sortBy) {
      case "ascending":
        return _.orderBy(
          items,
          [(item: CourseUser) => item.firstName.toLowerCase(),
           (item: CourseUser) => item.lastName.toLowerCase(),
           (item: CourseUser) => item.userName.toLowerCase()],
          ["asc"],
        );
      case "decending":
        return _.orderBy(
          items,
          [(item: CourseUser) => item?.firstName?.toLowerCase(),
           (item: CourseUser) => item?.lastName?.toLowerCase(),
           (item: CourseUser) => item.userName.toLowerCase()],
          ["desc"],
        );
      case "progress_most":
        return _.orderBy(
          items,
          [(item: CourseUser) => item.progress],
          ["desc"],
        );
      case "progress_least":
        return _.orderBy(items, [(item: CourseUser) => item.progress], ["asc"]);
      default:
        return items;
    }
  }
  // searching through the list of files & folder
  function searchUsers(usersList: Array<CourseUser>, query: string) {
    if (query.length < 1) {
      return usersList;
    }
    const resultUsers = usersList.filter(
      (user: CourseUser) =>
        user.firstName.toLowerCase().includes(query.toLowerCase()) ||
        user.lastName.toLowerCase().includes(query.toLowerCase()) ||
        user.userName.toLowerCase().includes(query.toLowerCase())
    );
    return resultUsers;
  }
  // opens sort menu list
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // closes the sort menu list
  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortBy = (sortBy: any) => {
    setSelectedSort(sortBy);
  };
  const refresh = () => {
    setRotateRefresh(true);
    handleRefresh();
    setTimeout(() => {
      setRotateRefresh(false);
    }, 1500);
  };

  const handleUserRowSelection = (user: any) => {
    
    dispatch(setSelectedUser(user));
    if (!showGrader) {
      dispatch(addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id }))
      dispatch(setShowGrader(true));
    }
  };
  return (
    <TableContainer
      sx={(theme) => ({
        // overflowX: 'hidden !important'
      })}
      cy-data="admin-course-user-table-container"
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "19px",
                }}
              >
                Users
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: "400px",
                fontSize: "15px",
              }}
            >
              Progress
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: "400px",
                fontSize: "15px",
              }}
            >
              {graderTable ? "Score" : "Last Signin"}
            </TableCell>
            {
              <TableCell align="left" sx={{ maxWidth: "150px" }}>
                {graderTable ? (
                  " "
                ) : (
                  <CustomSearchField
                    handler={setSearchQuery}
                    value={searchQuery}
                    placeHolder="Search For User"
                  />
                )}
              </TableCell>
            }
            {
              <TableCell
                align="right"
                sx={{
                  fontWeight: "400px",
                  fontSize: "15px",
                }}
              >
                {graderTable ? (
                  " "
                ) : (
                  <Stack flexDirection="row" gap={0.1} alignItems="center">
                    <IconButton
                      size="small"
                      className={rotateRefresh ? "rotate-refresh-icon" : ""}
                      onClick={refresh}
                    >
                      <CachedIcon titleAccess="Refresh Course" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={handleSortMenuOpen}
                      title="Sort"
                      id="sort-button"
                      aria-controls={
                        Boolean(anchorEl) ? "sort-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    >
                      <TuneIcon titleAccess="Sort by" />
                      {/* <img src={sortIcon} alt="sort" className="table-sort-icon" /> */}
                    </IconButton>
                    <Menu
                      id="sort-menu"
                      anchorEl={anchorEl}
                      transformOrigin={{ horizontal: "left", vertical: "top" }}
                      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                      open={Boolean(anchorEl)}
                      onClose={handleSortMenuClose}
                      onClick={handleSortMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "sort-button",
                      }}
                    >
                      <Typography
                        fontSize={10}
                        fontWeight={600}
                        pl={1}
                        color="text.secondary"
                      >
                        Sort by
                      </Typography>
                      <MenuItem
                        onClick={() => handleSortBy("ascending")}
                        sx={{ fontSize: "14px" }}
                      >
                        A → Z
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSortBy("decending")}
                        sx={{ fontSize: "14px" }}
                      >
                        Z → A
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSortBy("progress_most")}
                        sx={{ fontSize: "14px" }}
                      >
                        Most Progress
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSortBy("progress_least")}
                        sx={{ fontSize: "14px" }}
                      >
                        Least Progress
                      </MenuItem>
                    </Menu>
                  </Stack>
                )}
              </TableCell>
            }
          </TableRow>
        </TableHead>
        {/* table body is shown if data is availabel */}
        <TableBody
          sx={(theme) => ({
            minWidth: 600,
            "& .MuiTableRow-root:hover": {
              cursor: "pointer",
              backgroundColor: "var(--admin-table-row-hover)",
            },
          })}
        >
          {userData.length > 0 ? (
            userData?.map((user: any, index) => (
              <TableRow
                key={index}
                sx={{ height: "51px !important" }}
                onClick={() => handleUserRowSelection(user)}
              >
                <TableCell align="left">
                  {refreshingCourses?.includes(itemId) ? (
                    <Skeleton height="1.5rem" width={graderTable ? 100 : 150} />
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                      cy-data="admin-course-user-table-user-name"
                    >
                      {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` :user.userName} 
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="left">
                  {refreshingCourses?.includes(itemId) ? (
                    <Skeleton height="1.5rem" width={graderTable ? 100 : 150} />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        (user.progress / selectedCourse?.courseProgressPoints) *
                        100
                      }
                      cy-data="admin-course-user-table-user-progress"
                    />
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "400",
                    fontSize: "11px",
                  }}
                >
                  {refreshingCourses?.includes(itemId) ? (
                    <Skeleton height="1rem" width={graderTable ? 80 : 150} />
                  ) : graderTable ? (
                    `${parseFloat(user.progress).toFixed(2)}/${
                      selectedCourse?.courseProgressPoints || 100
                    }`
                  ) : (
                    moment(user.lastActivity).fromNow(true)
                  )}
                </TableCell>
                <TableCell align="left" sx={{ paddingRight: 0 }}>
                  {refreshingCourses?.includes(itemId) ? (
                    <Skeleton
                      variant="rounded"
                      height={graderTable ? 50 : 20}
                      width={graderTable ? 100 : 150}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      className={`role-button ${user?.role?.toLowerCase() || 'student'}`}
                      sx={{
                        minWidth: graderTable ? "85px !important" : "150px",
                        padding: graderTable ? "15px" : "0",
                        maxHeight: graderTable ? "45px" : "30px",
                        textTransform: "none",
                      }}
                      cy-data="admin-course-user-table-user-role-btn"
                    >
                      {user.role === "TeachingAssistant"
                        ? "Teaching Assistant"
                        : user.role}
                    </Button>
                  )}
                </TableCell>
                <TableCell>{}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {refreshingCourses?.includes(itemId) ? (
                  <Skeleton height="1.5rem" width={150} />
                ) : (
                  <Typography variant="h6">No user found..</Typography>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTableList;
