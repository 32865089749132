/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  // Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import x from "../../../../../../assets/img/cancel.svg";
import check from "../../../../../../assets/img/check.svg";
import reviewInprogress from "../../../../../../assets/img/inprogress.svg";
import questionIcon from "../../../../../../assets/img/question.svg";
import noPreviewAvailable from "../../../../../../assets/img/NoPreviewAvailable.jpg";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import Swal from "sweetalert2";
import {
  loadSingleCourseDataAction,
  updateAdminReviewForUsersFrqSolutionAction,
} from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import { parseMixedText } from "../../../../../../utils/util";
import { StyledAdminTextField } from "../../../../../components/styledComponents/customTextField";

type Props = {
  questionThemeColor: string;
  solution: any;
  questionType: string;
  loadUsersAnswerSubmitted: any;
  setUserProgressValue: any;
  handleViewTemplate?: any;
  getImage?: any;
  userProgressValue: any;
};
export const QuestionTypes = {
  mcq: "MultipleChoiceQuestion",
  frq: "FreeResponseQuestion",
  cq: "CodeQuestion",
};
const AnswerAccordion = ({
  questionThemeColor,
  solution,
  questionType,
  loadUsersAnswerSubmitted,
  setUserProgressValue,
  handleViewTemplate,
  getImage,
  userProgressValue,
}: Props) => {
  const [selectedDoc, setSelectedDoc] = useState({
    s3Url: noPreviewAvailable,
    documentDescription: "No Description provided",
    _id: "test",
  });
  const [clonedSolution, setClonedSolution] = useState(solution);
  const [hoveringImg, setHoveringImg] = useState("");
  const [reviewRemarks, setReviewRemarks] = useState("");
  const [reviewPoint, setReviewPoint] = useState(0.0);
  const [reviewPointError, setReviewPointError] = useState("");
  const [showButton, setShowButton] = useState(false);
  // const [copyText, setCopyText] = useState("Click to copy question id");
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { selectedCourse } = useAppSelector((state) => state.adminCourse);
  const dispatch = useAppDispatch();
  // const handleCopy = (id: any) => {
  //   navigator.clipboard.writeText(
  //     `${
  //       questionType === QuestionTypes.mcq
  //         ? "MultipleChoiceQuestion"
  //         : questionType === QuestionTypes.frq
  //         ? "FreeResponseQuestion"
  //         : questionType === QuestionTypes.cq
  //         ? "CodeQuiz"
  //         : ""
  //     }("${id}")`
  //   );
  //   setCopyText("CustomId copied...");
  //   setTimeout(() => {
  //     setCopyText(`Click to copy question id`);
  //   }, 300);
  // };
  const handleTemplateView = () => {
    if (
      questionType === QuestionTypes.frq &&
      clonedSolution?.question?.templateResponse
    ) {
      handleViewTemplate(
        clonedSolution?.question?.question,
        clonedSolution?.question?.templateResponse,
        clonedSolution?.question?.templateResponseFiles || []
      );
    }
  };

  const handleDocumentZoom = (item: any) => {
    setSelectedDoc(item);
    setShowDialog(true);
  };
  const handleSubmitReview = (accept: boolean) => {
    if (reviewPoint <= 0 && accept) {
      setReviewPointError("Please update the review point");
      return;
    }
    if (reviewPoint > clonedSolution?.question?.points && accept) {
      setReviewPointError(
        "The total points awarded is higher than the maximum points allowed for this question"
      );
      return;
    }
    setReviewPointError("");
    const payload = {
      pointAwarded: !accept ? 0 : reviewPoint,
      reviewRemarks: reviewRemarks,
      frqSolutionId: clonedSolution._id,
      answerAccepted: accept,
    };
    setIsUpdating(true);
    setShowDialog(true);
    dispatch(updateAdminReviewForUsersFrqSolutionAction(payload)).then(
      (data) => {
        if (
          data.type ===
          "adminCourse/updateAdminReviewForUsersFrqSolution/rejected"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Something went wrong. Please try again",
          });
          loadUsersAnswerSubmitted();
        }
        if (
          data.type ===
          "adminCourse/updateAdminReviewForUsersFrqSolution/fulfilled"
        ) {
          // const updatedUserProgress =
          //   parseFloat(userProgressValue) + reviewPoint;
          // accept && setUserProgressValue(updatedUserProgress);
          setClonedSolution((pre: any) => ({
            ...pre,
            score: !accept ? 0 : reviewPoint,
            reviewStatus: accept ? "accepted" : "rejected",
          }));
        }
        dispatch(loadSingleCourseDataAction({ courseId: selectedCourse._id }));
        setIsUpdating(false);
        setShowDialog(false);
      }
    );
  };
  useEffect(() => {
    setClonedSolution(solution);
    setReviewPoint(parseFloat(solution.score));
    if (questionType === QuestionTypes.frq) {
      setReviewRemarks(solution.reviewerRemarks);
    }
  }, [solution]);
  return (
    <Accordion
      sx={{
        width: "100%",
        borderBottom: `1px solid ${
          questionThemeColor === "blue"
            ? "#284bc0"
            : questionThemeColor === "pink"
            ? "#c02888"
            : "#7A2DC7"
        }`,
        position: "relative",
      }}
      cy-data="admin-course-grader-accordion"
    >
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
        sx={{
          ":hover": {
            backgroundColor: "--admin-accordion-hover-shadow",
          },
        }}
        expandIcon={
          <ExpandMoreIcon
            sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
          />
        }
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              position: "relative",
            }}
          >
            <img
              src={
                questionType === QuestionTypes.frq
                  ? clonedSolution.reviewStatus === "accepted"
                    ? check
                    : clonedSolution.reviewStatus === "rejected"
                    ? x
                    : reviewInprogress
                  : questionType === QuestionTypes.mcq
                  ? clonedSolution?.answerIsCorrect
                    ? check
                    : x
                  : questionIcon
              }
              height={20}
              width={20}
              alt="question-status-indicator"
              cy-data="admin-course-grader-frq-status-indicator"
            />
            {isUpdating && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  width: "20px !important",
                  height: "20px !important",
                }}
              />
            )}
            <Typography variant="h6">
              {clonedSolution?.question?.customId}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {/* <Tooltip
              title={copyText}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor:
                      questionThemeColor === "blue"
                        ? "#284bc0"
                        : questionThemeColor === "pink"
                        ? "#c02888"
                        : "#7A2DC7",
                    color: "white",
                  },
                },
              }}
              sx={{
                "& .MuiTooltip-arrow": {
                  color:
                    questionThemeColor === "blue"
                      ? "#284bc0"
                      : questionThemeColor === "pink"
                      ? "#c02888"
                      : "#7A2DC7",
                },
              }}
            >
              <IconButton onClick={() => handleCopy(question.customId)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip> */}
            {showButton && (
              <Button
                variant="qbookStyledBtn"
                sx={{
                  textTransform: "none",
                  minWidth: "77px",
                  height: "25px",
                  padding: "5px",
                  borderRadius: "5px",
                  display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                }}
              >
                View Response
              </Button>
            )}
            <Typography>
              Score : {parseFloat(clonedSolution?.score).toFixed(2) || 0}/
              {clonedSolution?.question?.points || 0}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ overflow: "hidden" }}>
        <TableContainer>
          <Table aria-label="accordion table">
            {/* table body is shown if data is availabel */}
            <TableBody
              sx={{
                minWidth: 650,
                "& .MuiTableRow-root:hover": {
                  backgroundColor: "var(--admin-table-row-hover)",
                  cursor: "pointer",
                },
              }}
            >
              <TableRow sx={{ height: "51px !important" }}>
                <TableCell align="left">
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Question
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    variant="body2"
                    cy-data="admin-course-grader-accordion-question"
                  >
                    {clonedSolution?.question?.question
                      ? clonedSolution?.question?.latexQuestion
                        ? parseMixedText(clonedSolution?.question?.question)
                        : clonedSolution?.question?.question
                      : "N/A"}
                  </Typography>
                </TableCell>
              </TableRow>

              {questionType === QuestionTypes.frq &&
                clonedSolution?.question?.templateResponse && (
                  <TableRow sx={{ height: "51px !important" }}>
                    <TableCell align="left">
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Template Response
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          handleTemplateView();
                        }}
                        cy-data="admin-course-grader-accordion-template-btn"
                      >
                        View Template
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              {questionType === QuestionTypes.frq && (
                <TableRow sx={{ height: "51px !important" }}>
                  <TableCell align="left">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Submitted Solution
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      cy-data="admin-course-grader-accordion-frq-solution"
                    >
                      {clonedSolution?.solutions || "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {questionType === QuestionTypes.frq && (
                <TableRow sx={{ height: "51px !important" }}>
                  <TableCell align="left">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" cy-data="admin-course-grader-accordion-frq-review-status">
                      {clonedSolution?.reviewStatus || "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              <TableRow sx={{ height: "51px !important" }}>
                <TableCell align="left">
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Submission Date
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">
                    {moment(new Date(clonedSolution?.createdAt)).format(
                      "MM-DD-YYYY"
                    ) || "N/A"}
                  </Typography>
                </TableCell>
              </TableRow>

              {questionType === QuestionTypes.frq && (
                <TableRow sx={{ height: "51px !important" }}>
                  <TableCell align="left" sx={{ fontWeight: 600 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Remarks
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {clonedSolution?.reviewStatus === "pending" ? (
                      <StyledAdminTextField
                        value={reviewRemarks}
                        onChange={(e) => setReviewRemarks(e.target.value)}
                        multiline
                        maxRows={3}
                        fullWidth
                        placeholder="Add your remarks"
                        variant="standard"
                        cy-data="admin-course-grader-accordion-frq-review-input"
                      />
                    ) : (
                      <Typography variant="body2">
                        {reviewRemarks || "N/A"}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}

              <TableRow sx={{ height: "51px !important" }}>
                <TableCell align="left">
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Points Awarded
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {questionType === QuestionTypes.frq &&
                  clonedSolution?.reviewStatus === "pending" ? (
                    <StyledAdminTextField
                      type="number"
                      value={reviewPoint}
                      onChange={(e) => {
                        reviewPointError && setReviewPointError("");
                        setReviewPoint(parseFloat(e.target.value));
                      }}
                      fullWidth
                      variant="standard"
                      error={Boolean(reviewPointError)}
                      helperText={reviewPointError}
                      cy-data="admin-course-grader-accordion-frq-points-input"
                    />
                  ) : (
                    <Typography variant="body2">
                      {parseFloat(reviewPoint.toString()).toFixed(2)}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              {questionType === QuestionTypes.mcq && (
                <TableRow sx={{ height: "51px !important" }}>
                  <TableCell align="left">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Attempt Count
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {clonedSolution?.questionAttempts}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {questionType === QuestionTypes.frq && (
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Supporing Documents
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      maxHeight={300}
                      overflow="auto"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1em",
                      }}
                      cy-data="admin-course-grader-accordion-frq-supporting-docs"
                    >
                      {clonedSolution?.submittedDocuments?.length === 0 ? (
                        <Typography variant="body2">
                          No documents submitted
                        </Typography>
                      ) : (
                        clonedSolution?.submittedDocuments?.map(
                          (docItem: any, index: any) => {
                            return (
                              <Box
                                key={`admin_clone_solu${index}`}
                                sx={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  overflow: "hidden",
                                  cursor: "zoom-in",
                                  ":hover": {
                                    border: "1px solid blueviolet",
                                  },
                                }}
                                onMouseEnter={() =>
                                  setHoveringImg(docItem.s3Url)
                                }
                                onMouseLeave={() => setHoveringImg("")}
                              >
                                <img
                                  src={getImage(docItem.s3Url)}
                                  alt="docsImg"
                                  key={`docsImg${index}`}
                                  width="100%"
                                  height="100%"
                                />
                                {hoveringImg === docItem.s3Url && (
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: "10%",
                                      left: "10%",
                                    }}
                                    onClick={() => handleDocumentZoom(docItem)}
                                  >
                                    <ZoomOutMapIcon />
                                  </IconButton>
                                )}
                              </Box>
                            );
                          }
                        )
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {questionType === QuestionTypes.frq &&
                clonedSolution?.reviewStatus === "pending" && (
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <Button
                        variant="qbookStyledBtn"
                        color="success"
                        onClick={() => {
                          handleSubmitReview(true);
                        }}
                        sx={{
                          marginRight: "5px",
                        }}
                        cy-data="admin-course-grader-accordion-frq-accept-btn"
                      >
                        Accept
                      </Button>
                      <Button
                        variant="qbookStyledBtn"
                        color="error"
                        onClick={() => {
                          handleSubmitReview(false);
                        }}
                        cy-data="admin-course-grader-accordion-frq-reject-btn"
                      >
                        Reject
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
      <Dialog
        open={showDialog}
        onClose={() => {
          !isUpdating && setShowDialog(false);
        }}
      >
        <DialogContent>
          {isUpdating ? (
            <Stack flexDirection="column" gap={1}>
              <Typography fontSize={16} color="text.primary">
                Please wait, Saving your response
              </Typography>
              <LinearProgress
                sx={{
                  backgroundColor:
                    questionThemeColor === "blue"
                      ? "#284bc080"
                      : questionThemeColor === "pink"
                      ? "#c0288878"
                      : "#7a2dc77a",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor:
                      questionThemeColor === "blue"
                        ? "#284bc0"
                        : questionThemeColor === "pink"
                        ? "#c02888"
                        : "#7A2DC7",
                  },
                }}
              />
            </Stack>
          ) : (
            <Grid container width={500} maxHeight={700} gap={2}>
              <Grid item xs={12} boxShadow={1}>
                <img
                  src={getImage(selectedDoc?.s3Url)}
                  alt="dialog-img-preview"
                  height="100%"
                  width="100%"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {selectedDoc.documentDescription}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  minHeight: "50px",
                }}
              >
                <a
                  target="_blank"
                  href={selectedDoc?.s3Url}
                  style={{
                    padding: ".5em",
                    textDecoration: "none",
                    background: "blueviolet",
                    borderRadius: "5px",
                    color: "#ffffff",
                  }}
                  rel="noreferrer"
                >
                  Download
                </a>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </Accordion>
  );
};

export default AnswerAccordion;
