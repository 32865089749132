import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import restart from "../../../../assets/img/restart.png";
import { useAppDispatch, useAppSelector } from "../../../../reduxStore/hooks";
import { RootState } from "../../../../reduxStore/store";
import { SITE_URL } from "../../../../utils/axiosInstance";
import { restartKernelAction } from "../../../../reduxStore/middleware/kernelMiddleware";
import AccountMenuDropdown from "./accountMenuDropdown";
import { Button } from "@mui/material";

type PropTypes = {
    kernelManagerOpen: boolean;
    reloadIfLoggedOut?: boolean;
    kernelDisplayName: string;
};

const KernelInfo = (props: PropTypes) => {
    const location = useLocation();
    const { email } = useAppSelector((state: RootState) => state.auth);
    const { currentKernel, kernelState } = useAppSelector(
        (state) => state.kernel
    );
    // const [accountInfoVisible, setAccountInfoVisible] = useState<boolean>(false);
    const [kernelInfoVisible, setKernelInfoVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const color = useMemo(() => {
        switch (kernelState) {
            case "Starting...":
                return "orange";
            case "Ready":
                return "green";
            case "Busy":
                return "slateblue";
            case "Failed":
                return "red";
            default:
                return "grey";
        }
    }, [kernelState]);
    useEffect(() => {
        setKernelInfoVisible(
            (location.pathname.includes("/blogs/") &&
                location.search.includes("?file")) ||
                location.pathname.includes("/learn/")
        );
    }, [location]);

    const handleLogin = () => {
        let route = window.location.href.replace(SITE_URL + "/", "");
        window.localStorage.setItem("redirectUri", route);
        navigate("/login/?redirectTo=/" + route);
    };

    const restartKernel = () => {
        // restart the kernel
        if (kernelState === "Starting..." || kernelState === "Busy") {
            return;
        }
        if (currentKernel?.id) {
            dispatch(restartKernelAction({ kernel_id: currentKernel.id }));
        }
    };

    if (email) {
        return (
            <div className="kernel-information" cy-data="kernel-information">
                {kernelInfoVisible && (
                    <div className="kernel-container">
                        <div className="kernel-name" cy-data="kernel-name">
                            {props.kernelDisplayName}
                        </div>
                        <div
                            className="kernel-status-ball"
                            cy-data="kernel-status-ball"
                            style={{
                                background:
                                    "linear-gradient(45deg," +
                                    "dark" +
                                    color +
                                    "," +
                                    color +
                                    ", white)",
                            }}
                        />
                        <div className="kernel-state">{kernelState}</div>
                        {kernelState !== "Unavailable" && (
                            <div
                                className="restart-container"
                                onClick={() => restartKernel()}
                            >
                                <img
                                    src={restart}
                                    alt="restart kernel"
                                    onClick={() => restartKernel()}
                                    className={`restart-btn ${
                                        kernelState === "Starting..." &&
                                        "rotate-icon"
                                    }`}
                                />
                                <div className="restart-text">
                                    {kernelState === "Starting..."
                                        ? "Loading"
                                        : "Restart"}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <AccountMenuDropdown />
            </div>
        );
    } else {
        // NEED THIS TO REDIRECT TO THE CORRECT ROUTE
        return (
            <div className="log-in-prompt" cy-data="qbook-header-login-btn">
                <Button
                    variant="outlined"
                    cy-data="header-log-in-prompt"
                    onClick={() => handleLogin()}
                >
                    {`Log In ${kernelInfoVisible ? "to Run Kernel" : ""}`}
                </Button>
            </div>
        );
    }
};

export default KernelInfo;
