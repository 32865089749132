import { Box, Grid, Slide, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useAppSelector } from "../../../../../../reduxStore/hooks";
import { DOMAIN_CONFIG_OBJ } from "../../../../../../utils/axiosInstance";
type Props = {};

const CourseOverViewCard = ({
  label,
  value,
  icon,
  bgColor,
  selectionHandler,
}: {
  label: string;
  value: number;
  icon: any;
  bgColor: string;
  selectionHandler?: () => {};
}) => {
  return (
    <Slide in={true} direction="up">
      <Grid
        item
        xs={12}
        sm={10}
        md={4}
        lg={2.8}
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          boxShadow: "var(--admin-course-over-view-shadow)",
          padding: ".5em 1em",
          gap: "1em",
        }}
        cy-data="qbook-admin-course-over-view-card"
      >
        <Box
          sx={{
            background: bgColor,
            color: "#ffffff",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50px",
            minWidth: "50px",
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            width: "100%",
            borderRadius: "5px",
            textAlign: "end",
          }}
        >
          <Typography
            variant="h6"
            color="lightgray"
            sx={{ paddingRight: "10px" }}
          >
            {label}
          </Typography>
          <Typography
            variant="body2"
            color="var(--admin-course-overview-text)"
            sx={{
              paddingRight: "10px",
              marginBottom: "5px",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            {value}
          </Typography>
        </Box>
      </Grid>
    </Slide>
  );
};
const CourseOverView = (props: Props) => {
  const [cardData, setCardData] = useState(
    DOMAIN_CONFIG_OBJ.admin.courses.courseOverviewCardData,
  );
  const { courses } = useAppSelector((state) => state.adminCourse);
  useEffect(() => {
    const totalCourses = courses.length || 0;
    let totalStudents = 0;
    let totalClasses = 0;
    let totalOrg = 0;
    courses.forEach((course) => {
      if (course?.classesRunning) {
        totalClasses += 1;
      }

      const students = course?.userList?.filter(
        (item: any) => item?.role!==null && item?.role === "Student",
      );
      totalStudents += students?.length || 0;
    });
    setCardData((pre) =>
      pre.map((data) => {
        if (data.id === "crs") {
          return { ...data, value: totalCourses };
        }
        if (data.id === "cls") {
          return { ...data, value: totalClasses };
        }
        if (data.id === "org") {
          return { ...data, value: totalOrg };
        }
        if (data.id === "std") {
          return { ...data, value: totalStudents };
        }
        return data;
      }),
    );
  }, [courses]);
  return (
    <Grid
      container
      sx={{
        display: "flex",
        gap: "1em",
        justifyContent: "center",
        maxHeight: "300px",
        overflow: "auto",
        padding: "10px 0",
      }}
    >
      {cardData.map((data, index) => {
        return (
          <CourseOverViewCard
            bgColor={data.bgColor}
            icon={data.icon}
            label={data.label}
            value={data.value}
            key={data.label}
          />
        );
      })}
    </Grid>
  );
};

export default CourseOverView;
