import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "../../../../../reduxStore/hooks";
import {
  authSignUp,
  federatedSignIn,
} from "../../../../../reduxStore/middleware/authMiddleware";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { ModalTypes } from "../..";
import googleImg from "../../../../../assets/img/google.png";
import { DOMAIN_CONFIG_OBJ, QBOOK_URL } from "../../../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../../../utils/constants/domainNameValues";

interface ISignUpProps {
  open: boolean;
  toggleAuthFlowPage: (modalToBeOpened: ModalTypes) => void;
}

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  acceptTerms: false,
  marketing: false,
};

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Must be 8 characters or more")
    .required("Password is required")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),

  confirmPassword: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("password")], "Your passwords do not match.")
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  acceptTerms: yup
    .bool()
    .oneOf([true], "Please accept our Terms & Conditions!")
    .required("Please accept our Terms & Conditions!"),
});

const SignUp = ({ open, toggleAuthFlowPage }: ISignUpProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const dispatch = useAppDispatch();

  const responseGoogle = () => {
    console.log("signing in with Google");
    dispatch(federatedSignIn(CognitoHostedUIIdentityProvider.Google)).then(
      (data: any) => {
        console.log("data from federated sign in ", data);
      },
    );
  };

  const showPasswordHandler = (): void => setShowPassword((prev) => !prev);
  const showConfirmPasswordHandler = (): void => {
    setShowConfirmPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { firstName, lastName, marketing } = values;
      const attributes = {
        "custom:firstName": firstName,
        "custom:lastName": lastName,
        "custom:marketing": marketing.toString(), // must be string for Cognito
      };
      try {
        const response = await dispatch(authSignUp({ values, attributes }));
        if (response) {
          setLoading(false);
          formik.handleReset(values);
          toggleAuthFlowPage("login");
        }
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      hideBackdrop
      PaperProps={{
        sx: {
          backgroundColor: "var(--auth-form-bg)",
          // width: 480,
          p: "1em 2em",
          borderRadius: "8px",
          boxShadow: "0px 1px 15px rgba(199, 199, 199, 0.5)",
          color: theme.palette.common.white,
        },
      }}
      aria-label="login form"
      cy-data="login-form"
    >
      <DialogContent sx={{ padding: "12px 16px", pt: 0 }}>
        <Stack
          px={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className={`login-logo ${
              DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam
                ? "login-logo-qusteam"
                : DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qbraid
                  ? "login-logo-qbraid"
                  : ""
            }`}
            src={DOMAIN_CONFIG_OBJ.darkThemeLogo}
            alt="qbook logo"
            cy-data="login-logo"
          />
        </Stack>
        <form
          aria-label="create account"
          spellCheck="false"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                id="googleSignInButton"
                fullWidth
                onClick={responseGoogle}
                size="large"
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={googleImg} alt="logo" width="28px" height="28px" />
                Sign in with Google
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "var(--accent-clr2)",
                  },
                }}
              >
                OR
              </Divider>
            </Grid>
            <Grid item container spacing={2}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <TextField
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  sx={{ flex: 1, minWidth: "140px" }}
                  variant="outlined"
                  placeholder="First Name"
                  name="firstName"
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  cy-data="firstName"
                />
                <TextField
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  sx={{ flex: 1, minWidth: "140px" }}
                  variant="outlined"
                  placeholder="Last Name"
                  name="lastName"
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  cy-data="lastName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder="Email Address"
                  name="email"
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  cy-data="email"
                  required
                />
              </Grid>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder="Create Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  fullWidth
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  cy-data="password"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityOffIcon
                            onClick={showPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={showPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize={14} color={theme.palette.grey[500]}>
                  Password must be 8 characters long with number and special
                  character.
                </Typography>
              </Grid>

              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  fullWidth
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showConfirmPassword ? (
                          <VisibilityOffIcon
                            onClick={showConfirmPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={showConfirmPasswordHandler}
                            sx={{
                              color: "#2E3338",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  cy-data="confirmPassword"
                />
              </Grid>

              <Grid
                sx={{
                  borderBottom: "1px solid #CCCCCC",
                  display: "flex",
                  alignItems: "center",
                }}
                container
                item
                xs={12}
              >
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                      "&.Mui-checked": { color: "var(--mui-checked)" },
                      paddingLeft: "0px!important",
                      marginLeft: "-3px!important",
                      color: "var(--text-gray4)",
                    }}
                    onChange={formik.handleChange}
                    name="marketing"
                    value={formik.values.marketing}
                  />
                </Grid>

                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <Typography
                    fontSize={16}
                    sx={{ color: "var(--text-blogs-gray3)" }}
                  >
                    Subscribe to {DOMAIN_CONFIG_OBJ.domainName} newsletter.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Checkbox
                    name="acceptTerms"
                    value={formik.values.acceptTerms}
                    onChange={formik.handleChange}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: "24px" },
                      "&.Mui-checked": { color: "var(--mui-checked)" },
                      paddingLeft: "0px!important",
                      marginLeft: "-3px!important",
                      color: "var(--text-gray4)",
                    }}
                    cy-data="acceptTerms"
                  />
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <Typography
                    fontSize={16}
                    sx={{ color: "var(--text-blogs-gray3)" }}
                  >
                    I have read and agree to {DOMAIN_CONFIG_OBJ.domainName}
                    ’s&nbsp;
                    <Link
                      aria-label="Terms & Conditions opens in new tab"
                      target="_blank"
                      href={`${QBOOK_URL}/terms-and-conditions`}
                      sx={{
                        color: "var(--text-inverted-clr1)",
                        ":hover": {
                          color: "var(--accent-clr2)",
                        },
                      }}
                    >
                      Terms & Conditions,
                    </Link>
                    &nbsp;
                    <Link
                      sx={{
                        color: "var(--text-inverted-clr1)",
                        ":hover": {
                          color: "var(--accent-clr2)",
                        },
                      }}
                      className="linktext"
                      aria-label="Privacy Policy opens in new tab"
                      target="_blank"
                      href={`${QBOOK_URL}/privacy-policy`}
                    >
                      Privacy Policy
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      sx={{
                        color: "var(--text-inverted-clr1)",
                        ":hover": {
                          color: "var(--accent-clr2)",
                        },
                      }}
                      aria-label="Cookie Policy opens in new tab"
                      target="_blank"
                      href={`${QBOOK_URL}/cookie-policy`}
                      className="linktext"
                    >
                      Cookie Policy.
                    </Link>
                  </Typography>
                  {formik.touched.acceptTerms &&
                    Boolean(formik.errors.acceptTerms) && (
                      <Typography
                        fontSize={13}
                        sx={{ color: "var(--text-error2)" }}
                      >
                        {formik.errors.acceptTerms}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                  <Typography fontSize={14} sx={{ color: "var(--text-gray4)" }}>
                    Check your email to verify your account
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="qbookAuth"
                size="large"
                color="authSubmit"
                type="submit"
                value="submit"
                cy-data="submit"
                fullWidth
              >
                {loading ? "Loading..." : "Create Account"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <p className="sign-up-link">
          <span>Already have an account? </span>
          <button
            className="sign-up-link-text"
            onClick={() => toggleAuthFlowPage("login")}
          >
            Log in
          </button>
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default SignUp;
