import { createSlice } from "@reduxjs/toolkit";
import { NavChildren, NavType, NavigationArray } from "../types/NavArrayType";
import { QBOOK_URL } from "../../utils/axiosInstance";
import {
  QUERY_COURSE,
  QUERY_FILE,
} from "../../utils/constants/urlPramsConstants";
import { LearnInfoType, SectionType } from "../types/LearnTypes";

export interface NavState {
  navigationArray: NavigationArray;
  loadNavigation: boolean;
  nextPageInfo: any;
  prevPageInfo: any;
}
type CreateNavPayloadType = {
  courseInfo: LearnInfoType;
  courseId: string;
};
const initialState: NavState = {
  loadNavigation: false,
  navigationArray: [],
  nextPageInfo: undefined,
  prevPageInfo: undefined,
};

const handleNavClick = (link?: string) => {
  window.location.href = QBOOK_URL + "/learn/" + link;
};

const navSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    createNavArray: (state, { payload }) => {
      const { courseInfo, courseId }: CreateNavPayloadType = payload;
      state.loadNavigation = true;
      const navArray: Array<NavType> = [];
      if (courseInfo !== undefined) {
        Object.keys(courseInfo).forEach((key: string, index: number) => {
          const chapter = courseInfo[key as keyof LearnInfoType];
          const chapterNavChildren: NavChildren = {
            id: chapter._id,
            label: chapter.chapterFileName || chapter.chapterName,
            key: chapter.chapterName + "_" + index,
            onClick: () => {
              handleNavClick(
                `?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${chapter._id}`
              );
            },
          };
          const chapterItem = {
            label: chapter.chapterName,
            key: chapter.customId,
            children: [chapterNavChildren] as NavChildren[],
            onClick: () => {},
          };
          if (chapter.sections) {
            chapter.sections.forEach((section: SectionType) => {
              const navChildren: NavChildren = {
                id: section._id,
                label: section.sectionName,
                key: section.sectionName,
                onClick: () => {
                  handleNavClick(
                    `?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${section._id}`
                  );
                },
              };
              chapterItem.children.push(navChildren);
            });
          }
          navArray.push(chapterItem);
        });
      }

      const currentFileId = new URL(window.location.href).searchParams.get(QUERY_FILE);
      const flattedNavArrayWithChildren = navArray.flatMap(chapter => chapter.children);
    
      if (flattedNavArrayWithChildren.length) {
        const currentFileIndex = flattedNavArrayWithChildren.findIndex(child => child.id === currentFileId);
        const prevIndex = currentFileIndex - 1;
        const nextIndex = currentFileIndex + 1;
    
        state.prevPageInfo = prevIndex >= 0 ? flattedNavArrayWithChildren[prevIndex] : undefined;
        state.nextPageInfo = nextIndex < flattedNavArrayWithChildren.length ? flattedNavArrayWithChildren[nextIndex] : undefined;
      } else {
        state.prevPageInfo = undefined;
        state.nextPageInfo = undefined;
      }

      state.navigationArray = navArray;
      state.loadNavigation = false;
    },
  },
  extraReducers: {},
});

export const { createNavArray } = navSlice.actions;
export default navSlice.reducer;
