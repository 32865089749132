import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  authSignIn,
  federatedSignIn,
  fetchUser,
  getUserIdentityAction,
  pollUserAction,
  signOutUser,
} from "../middleware/authMiddleware";
import { setCookies } from "../../utils/cookieHelper";
import { UserData } from "../types/AuthTypes";

export interface UserState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: any;
  userData: UserData | undefined;
  email: string;
  error: string | undefined;
}
const initialState: UserState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
  userData: undefined,
  email: "",
  error: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.email = action?.payload?.attributes?.email;
      },
    );
    builder.addCase(fetchUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.user = {};
      state.isAuthenticated = false;
      state.email = "";
      state.error = action?.payload?.message;
    });
    builder.addCase(federatedSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      federatedSignIn.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.email = action.payload.attributes.email;
      },
    );
    builder.addCase(
      federatedSignIn.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = {};
        state.isAuthenticated = false;
        state.error = action.payload.message;
      },
    );
    builder.addCase(authSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      authSignIn.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.email = action.payload.attributes.email;
      },
    );
    builder.addCase(
      authSignIn.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = {};
        state.isAuthenticated = false;
        state.error = action.payload.message;
      },
    );
    builder.addCase(signOutUser.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = undefined;
      state.email = "";
      state.userData = undefined;
    });
    builder.addCase(pollUserAction.pending, (state, action) => {});
    builder.addCase(
      pollUserAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload !== undefined) {
          state.isLoading = false;
          state.isAuthenticated = true;
          state.user = action.payload;
          state.email = action.payload.attributes.email;
        }
      },
    );
    builder.addCase(pollUserAction.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = undefined;
      state.email = "";
    });

    builder.addCase(getUserIdentityAction.pending, (state, action) => {});
    builder.addCase(
      getUserIdentityAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userData = action.payload;
      },
    );
    builder.addCase(getUserIdentityAction.rejected, (state) => {
      state.userData = undefined;
    });
  },
  reducers: {
    generateAuthCookies: (state, action: PayloadAction<any>) => {
      let refreshToken = action.payload.signInUserSession.refreshToken.token;
      let now = new Date();
      let oneWeek = now.setHours(now.getHours() + 168);
      let email = action.payload.attributes.email;
      window.localStorage.setItem("qbraidUserLoggedIn", "true");
      // breaks here since user doesnt have email if oauth
      setCookies(email, refreshToken, oneWeek);
    },
  },
});

export const { generateAuthCookies } = authSlice.actions;
export default authSlice.reducer;
