import {
  Box,
  Skeleton,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

type Props = {};
type CardLoaderProp = {
  selectedView?: boolean;
};
export const CourseCardLoader = ({ selectedView }: CardLoaderProp) => {
  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "100%",
          minHeight: "217px",
          maxHeight: "217px",
          boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          alignItems: "flex-start",
          gap: "1em",
          padding: "1.5em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Skeleton width="50%" height={50} />
          {selectedView && <Skeleton width="100px" height={20} />}
        </Box>

        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} sx={{ marginTop: "50px" }} />
        <Skeleton width={100} height={60} sx={{ placeSelf: "flex-end" }} />
      </Box>
    </Slide>
  );
};

type TableLoaderTypeProp = {
  graderTable?: boolean;
};
export const CourseUserTableLoader = ({ graderTable }: TableLoaderTypeProp) => {
  return (
    <TableContainer
      sx={(theme) => ({
        overflowX: "hidden !important",
      })}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Skeleton width={50} />
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: "400px",
                fontSize: "15px",
              }}
            >
              <Skeleton width={50} />
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: "400px",
                fontSize: "15px",
              }}
            >
              <Skeleton width={50} />
            </TableCell>
            {
              <TableCell align="left" sx={{ maxWidth: "150px" }}>
                {graderTable ? " " : <Skeleton width={100} />}
              </TableCell>
            }
            {
              <TableCell
                align="right"
                sx={{
                  fontWeight: "400px",
                  fontSize: "15px",
                }}
              >
                {graderTable ? (
                  " "
                ) : (
                  <Stack flexDirection="row" gap={0.1} alignItems="center">
                    <Skeleton width={50} />
                    <Skeleton width={50} />
                  </Stack>
                )}
              </TableCell>
            }
          </TableRow>
        </TableHead>
        {/* table body is shown if data is availabel */}
        <TableBody
          sx={{
            minWidth: 400,
            "& .MuiTableRow-root:hover": {
              backgroundColor: "#8D36D126",
              cursor: "pointer",
            },
          }}
        >
          {Array(3)
            ?.fill("")
            ?.map((user: any, index) => (
              <TableRow key={index} sx={{ height: "51px !important" }}>
                <TableCell align="left">
                  <Skeleton height="1.5rem" width={150} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton height="1.5rem" width={150} />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "400",
                    fontSize: "11px",
                  }}
                >
                  <Skeleton height="1rem" width={150} />
                </TableCell>
                <TableCell align="left" sx={{ paddingRight: 0 }}>
                  <Skeleton variant="rounded" height={20} width={150} />
                </TableCell>
                <TableCell>{}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const CourseLoaders = (props: Props) => {
  return <div>CourseLoaders</div>;
};

export default CourseLoaders;
